//sdk方式调用接口
import serviceBase from "@/store/http/serviceBase";
import systemConfig from "./project.config";
export default {
	getUrlSearch(name) {
		// 未传参，返回空
		if (!name) return null;
		// 查询参数：先通过search取值，如果取不到就通过hash来取
		var after = window.location.search;
		after = after.substr(1) || window.location.hash.split("?")[1];
		// 地址栏URL没有查询参数，返回空
		if (!after) return null;
		// 如果查询参数中没有"name"，返回空
		if (after.indexOf(name) === -1) return null;

		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
		// 当地址栏参数存在中文时，需要解码，不然会乱码
		var r = decodeURI(after).match(reg);
		// 如果url中"name"没有值，返回空
		if (!r) return null;

		return r[2];
	},
	encodeSearchParams(obj) {
		const params = [];
		Object.keys(obj).forEach((key) => {
			let value = obj[key];
			// 如果值为undefined我们将其置空
			if (typeof value === "undefined") {
				value = "";
			}
			// 对于需要编码的文本（比如说中文）我们要进行编码
			params.push([key, encodeURIComponent(value)].join("="));
		});

		return params.join("&");
	},
	//  priority，优先级
	// accessibility，易获得性
	// timeConst，时间常数
	// objTypeLbl，对象类所属类型标签信息
	//字典枚举值-查询
	async wikiHttpGet(hingeUrl, queryName) {
		var serviceUrl = systemConfig.requestIp;
		if (serviceUrl == null || serviceUrl == "") return;
		var path =
			"/dp-wiki-server/" +
			hingeUrl +
			(queryName ? "?" + this.encodeSearchParams(queryName) : "");
		var result = await serviceBase.GetJson(path);
		return result;
	},

	async wikiHttpPost(hingeUrl, params) {
		var serviceUrl = systemConfig.requestIp;
		if (serviceUrl == null || serviceUrl == "") return;
		var path = "/dp-wiki-server/" + hingeUrl;
		var result = await serviceBase.PostJson(path, params);
		return result;
	},

	async wikiFormDataPost(hingeUrl, params, fn) {
		var serviceUrl = systemConfig.requestIp;
		if (serviceUrl == null || serviceUrl == "") return;
		var path = "/dp-wiki-server/" + hingeUrl;
		const options = {
			url: path,
			method: "post",
			headers: { "Content-Type": "multipart/form-data" },
			data: params,
			onUploadProgress: fn,
		};
		var result = await serviceBase.axios(options);
		return result;
	},

	async wikiDownPost(hingeUrl, params) {
		var serviceUrl = systemConfig.requestIp;
		if (serviceUrl == null || serviceUrl == "") return;
		var path = "/dp-wiki-server/" + hingeUrl;
		const options = {
			url: path,
			method: "post",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			data: params,
		};
		var result = await serviceBase.axios(options);
		return result;
	},

	async admGet(params, queryName) {
		var result = await serviceBase.GetJson(
			params + "?" + (queryName ? this.encodeSearchParams(queryName) : "")
		);
		return result;
	},
};
